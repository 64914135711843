import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'demo',
    component: () => import('../views/demo.vue')
  },
  {
    path: '/alarm',
    name: 'alarm',
    component: () => import('../views/alarm.vue')
  },
  {
    path:'/circuitMonitoring',
    name:'circuitMonitoring',
    component: () => import('../views/circuitMonitoring.vue')
  },
  {
    path:'/log',
    name:'log',
    component: () => import('../views/log.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
